import request from 'src/api/request'
import requestPro from 'src/api/request2'

export const queryQuestionList = data =>
  requestPro({
    method: 'post',
    url: '/questionnaire/queryQuestionList',
    data,
  })
export const updateQuestion = data =>
  requestPro({
    method: 'post',
    url: '/questionnaire/updateQuestion',
    data,
  })
export const createQrCode = questionId =>
  requestPro({
    method: 'get',
    url: `/questionnaire/createQrCode/${questionId}`,
  })
export const downQuestionAnswerAll = data =>
  requestPro({
    method: 'post',
    url: '/questionnaire/downQuestionAnswerAll',
    data,
    responseType: 'blob',
  })
