<template>
  <div class="satisfaction-question-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="问卷名称">
              <el-select v-model="searchFrom.questionId" clearable placeholder="请选择问卷名称">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item,index) in modelList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="date" clearable value-format="yyyy-MM-dd" :picker-options="pickerOptionsStart" placeholder="请选择开始时间"
                              v-model="searchFrom.startTime"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="date" clearable value-format="yyyy-MM-dd" :picker-options="pickerOptionsEnd" placeholder="请选择结束时间"
                              v-model="searchFrom.endTime"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="searchResule">查询
              </el-button>
              <el-button @click="downloadFn" class="updatebutton" size="small">下载</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="card" shadow="always">
      <div class="table-block">
        <el-table :data="tableData" ref="multipleTable" v-loading="loading"
                  :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}"
                  :cell-style="{'text-align':'center'}" style="width: 100%">
          <el-table-column prop="name" label="客户姓名"></el-table-column>
          <el-table-column prop="questionName" label="问卷名称"></el-table-column>
          <el-table-column prop="createTime" label="提交时间"></el-table-column>
          <el-table-column prop="phone" label="联系电话"></el-table-column>
          <el-table-column label="操作" fixed="right" width="140">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row, true)" type="text" size="small">查看问卷</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        :current-page="pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="dialogProps.visible" width="50%" :title="dialogProps.title" :close-on-click-modal="false"
               @close="closeDialog">
      <questionSurveyDetail v-if="dialogProps.visible" :factionType="dialogProps.params.questionId"
                            :questionId="dialogProps.params.id" />
    </el-dialog>
  </div>
</template>

<script>
import { postRequest, postRequestAnthor, postRequestDown } from "@/api";
import questionSurveyDetail from "@/views/questionsurvey/components/questionSurveyDetail";
import { downQuestionAnswerAll } from "@/api/modules/questionTemplate";

export default {
  components: { questionSurveyDetail },
  data() {
    return {
      searchFrom: {
        questionId: "",
        time: ""
      },
      tableData: [{}],
      total: null,
      pageNo: 1,
      pageSize: 10,
      loading: false,
      disabledSearch: false,
      info: {},
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.searchFrom.endTime) {
            return time.getTime() > new Date(this.searchFrom.endTime).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.searchFrom.startTime) {
            return time.getTime() < new Date(this.searchFrom.startTime).getTime() - 86400000
          }
        }
      },
      companyList: [],
      modelList: [
        { label: "会员健康管理服务", value: "2" },
        { label: "FoodSense食物敏感检测", value: "3" },
        { label: "肠道健康管理", value: "4" },
        { label: "定制体检", value: "5" }
      ],
      dialogProps: {
        visible: false,
        params: {},
        title: "满意度调查详情"
      }
    };
  },
  mounted() {
    this.getAllNotice();
  },
  methods: {
    downloadFn() {
      downQuestionAnswerAll(this.searchFrom).then(res => {
        const blob = new Blob([res], { type: res.type });
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = URL.createObjectURL(blob);
        aLink.download = `满意度调查问卷-${new Date().getTime()}.zip`;
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
      });
    },
    closeDialog() {
      this.dialogProps.visible = false;
    },
    searchResule() {
      this.pageNo = 1;
      this.getAllNotice();
    },
    handleClick(val, type) {
      this.dialogProps = {
        visible: type,
        params: _.cloneDeep(val),
        title: "满意度调查详情"
      };
    },
    getAllNotice() {
      this.disabledSearch = true;
      this.loading = true;
      let data = {
        questionId: this.searchFrom.questionId || "",
        startTime: this.searchFrom.startTime || "",
        endTime: this.searchFrom.endTime || "",
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      postRequestAnthor("/questionnaire/queryQuestionAnswerList", data).then(res => {
        console.log(res);
        this.total = res.count;
        this.loading = false;
        this.disabledSearch = false;
        this.tableData = res.data;
      }).catch(err => {
        this.loading = false;
        this.disabledSearch = false;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getAllNotice();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
      this.getAllNotice();
    }
  }
};
</script>

<style lang="scss" scoped>
.satisfaction-question-page {
  background: transparent;
}

.searchFrom > .el-form-item {
  margin-right: 30px !important;
}

.Selectnum {
  padding: 6px 30px;
  border: 1px solid #BFCBF4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
