<template>
    <div class="main">
        <el-card class="card-1">
            <div class="content">
                <div class="item_box">
                    <div>亲爱的阿兰贝尔会员：</div>
                    <p class="txt">您好！非常感谢您对阿兰贝尔的信任和支持！</p>
                    <p class="txt"> 为了解您对{{factionType == 4 ? '肠道管理' :'会员服务'}}的感受和评价，诚邀您抽出宝贵的时间，对本次服务进行点评，以便我们给您提供更优质的产品和服务。
                        您的认可和建议都是我们不断成长的行动力，我们期待您的反馈。</p>
                </div>
                <!-- 健康管理效果 -->
                <div class="item_box mt15" v-if="[3, 2, 4].includes(factionType)">
                    <div class="title" v-if="factionType == '3'">产品受益效果</div>
                    <div class="title" v-else-if="factionType == '4'">产品管理效果：</div>
                    <div class="title" v-else>健康管理效果</div>
                    <div class="text mt15">
                        <div class="rate_box">
                            <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                >
                                <img :src="form.fwdf.jkgl == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                    alt="">
                                <span :class="form.fwdf.jkgl == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 服务流程满意度 -->
                <div class="item_box mt15" v-if="[2, 5].includes(factionType)">
                    <div class="title" v-if="factionType === 2">服务流程满意度</div>
                    <div class="title" v-else>体检流程满意度：</div>
                    <div class="text mt15">
                        <div class="rate_box">
                            <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                               >
                                <img :src="form.fwdf.fwlc == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                    alt="">
                                <span :class="form.fwdf.fwlc == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 就诊环境满意度 -->
                <div class="item_box mt15" v-if="[2].includes(factionType)">
                    <div class="title">就诊环境满意度</div>
                    <div class="text mt15">
                        <div class="rate_box">
                            <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                               >
                                <img :src="form.fwdf.jzhj == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                    alt="">
                                <span :class="form.fwdf.jzhj == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第三方机构体验满意度 -->
                <div class="item_box mt15" v-if="[3, 2, 5].includes(factionType)">
                    <div class="title">{{factionType == '3' ? '采样体验满意度':'第三方机构体验满意度'}}</div>
                    <div class="text mt15">
                        <div class="rate_box">
                            <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                >
                                <img :src="form.fwdf.thirdjg == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                    alt="">
                                <span :class="form.fwdf.thirdjg == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 工作人员服务 -->
                <div class="item_box mt15" v-if="[3, 2, 4, 5].includes(factionType)">
                    <div class="title">工作人员服务{{factionType != 3 ?':（专业度，服务态度）' : ""}}</div>
                    <!-- 主管医生： -->
                    <div class="text mt15" v-if="[2, 4, 5].includes(factionType)">
                        <div>
                            <div class="label_txt">主管医生：</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                       >
                                        <img :src="form.fwdf.gzryfw.zgys == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.zgys == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- 超声医生： -->
                    <div class="text mt15" v-if="[2, 5].includes(factionType)">
                        <div>
                            <div class="label_txt">超声医生：</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                        >
                                        <img :src="form.fwdf.gzryfw.csys == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.csys == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- 营养师： -->
                    <div class="text mt15" v-if="[3, 2, 4].includes(factionType)">
                        <div>
                            <div class="label_txt">{{factionType == 3 ? '营养师：(报告解读，营养咨询，膳食指导，服务态度)' : '营养师：'}}</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                        >
                                        <img :src="form.fwdf.gzryfw.yys == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.yys == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- 运动师： -->
                    <div class="text mt15" v-if="[2].includes(factionType)">
                        <div>
                            <div class="label_txt">运动师：</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                        >
                                        <img :src="form.fwdf.gzryfw.yds == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.yds == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 健康管理师 -->
                    <div class="text mt15" v-if="[2, 4, 5].includes(factionType)">
                        <div>
                            <div class="label_txt">健康管理师:</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                        >
                                        <img :src="form.fwdf.gzryfw.jkgls == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.jkgls == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 护士 -->
                    <div class="text mt15" v-if="[2, 3, 5].includes(factionType)">
                        <div>
                            <div class="label_txt">{{factionType == 3 ? '护士：服务态度' : '护士:'}}</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                        >
                                        <img :src="form.fwdf.gzryfw.hs == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.hs == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  客户管家 -->
                    <div class="text mt15" v-if="[2, 3, 4, 5].includes(factionType)">
                        <div>
                            <div class="label_txt">{{factionType == 3 ? '客户管家：采样指导，服务态度' : '客户管家:'}}</div>
                            <div class="mt15">
                                <div class="rate_box">
                                    <div class="rate_item" v-for="(item) in rateList" :key="item.index"
                                        >
                                        <img :src="form.fwdf.gzryfw.khgj == item.index ? './img/application/rateActive.png' : './img/application/rateIn.png'"
                                            alt="">
                                        <span
                                            :class="form.fwdf.gzryfw.khgj == item.index ? 'rateActive' : 'rate'">{{ item.txt }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 您向家人/朋友推荐阿兰贝尔的可能性有多大？ -->
                <div class="item_box mt15">
                    <div class="title">您向家人/朋友推荐阿兰贝尔的可能性有多大？</div>
                    <div class="text mt15">
                        <div class="rate_box">
                            <div class="rate_item" v-for="(item) in 10" :key="item.index">
                                <div class="radius" :class="form.sftj == item ? 'radiusActive' : 'radiu'">{{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 您最注重会员健康管理服务中的哪些体验？ -->
                <div class="item_box mt15">
                    <div class="title">您最注重{{ facationTypeObj[factionType] }}中的哪些体验？</div>
                    <div class="text mt15">
                        <el-input type="textarea" disabled placeholder="请输入内容" :autosize="{ minRows: 3, maxRows: 5 }"
                            v-model="form.followWhat" show-word-limit></el-input>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import { queryQuestionAnswer } from '@/api/modules/questionAnswer'
export default {
    name: 'questionSurvey',
    data() {
        return {
            form: {
                "fwdf": {
                    "jkgl": '',
                    "fwlc": '',
                    "jzhj": '',
                    "thirdjg": '',
                    "gzryfw": {
                        "zgys": '',
                        "csys": '',
                        "yys": '',
                        "yds": '',
                        "jkgls": '',
                        "hs": '',
                        "khgj": ''
                    }
                },
                "sftj": '',
                "followWhat": ""
            },
            facationTypeObj: {
                1: 'FoodSense申请单问卷',
                2: '会员健康管理服务',
                3: 'FoodSense食物敏感检测服务',
                4: '肠道健康管理',
                5: '定制体检',
            },
            rateList: [
                { txt: '非常不满意', index: 1 },
                { txt: '不满意', index: 2 },
                { txt: '一般', index: 3 },
                { txt: '满意', index: 4 },
                { txt: '非常满意', index: 5 },
            ]
        }
    },
    methods: {
        getQutionInfo(questionId) {
            queryQuestionAnswer(questionId)
                .then(res => {
                    Object.assign(this.form, JSON.parse(res.answerText))
                })
        }
    },
    created() {
        this.questionId && this.getQutionInfo(this.questionId)
    },
    props: {
        factionType: {
            type: Number,
            default: 2
        },
        questionId: {
            default: ''
        }
    }
}
</script>
<style lang="scss" scoped>
.content {
    padding-top: 12px;
    /* height: 100vh; */
    margin: 0 16px;
    padding-bottom: 140px;
}

.content .item_box {
    background-color: #fff;
    padding: 16px;
    font-size: 14px;
    color: #3D4961;
    font-weight: 400;
}

.content .item_box .txt {
    text-indent: 2em;
}

.content .item_box .title {
    color: #3D4961;
    font-weight: bold;
    font-size: 14px;
}

.content .item_box .label_txt {
    font-size: 14px;
    font-weight: 500;
}

.content .item_box .rate_box {
    display: flex;
    justify-content: space-between;
}

.content .item_box .van-cell {
    background-color: #F8F8F8;
}

.content .item_box .rate_box .rate_item {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.content .item_box .rate_box .rate_item .radius {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #F1F1F1;
    color: #999;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
}

.content .item_box .rate_box .rate_item .radiusActive {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    background-color: #5591FF;
}

.content .item_box .rate_box .rate_item .radiu {
    color: #999;
    font-size: 12px;
    font-weight: 400;
}

.content .item_box .rate_box .rate_item .rateActive {
    color: #5591FF;
    font-size: 12px;
    font-weight: 400;
}

.content .item_box .rate_box .rate_item .rate {
    font-weight: 400;
    font-size: 12px;
    color: #8A8A8A;
}

.content .item_box .rate_box .rate_item img {
    width: 28px;
    height: 28px;
    margin-bottom: 8px;
}

.content .item_box .rate_box .rate_item .rate {
    font-size: 12px;
    color: #8A8A8A;
    font-weight: 400;
}

.content .mt15 {
    margin-top: 15px;
}
</style>
